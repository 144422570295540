var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.storageSpaceMediasData),function(storageSpaceMedia,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":storageSpaceMedia.storageSpaceMediaNameCurrent,"description":storageSpaceMedia.fullCode,"imagePath":storageSpaceMedia.storageSpaceMediaFilePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
          _vm.openBottomSheet('StorageSpaceMediaInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('QR.modelName')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
          _vm.openBottomSheet('StorageSpaceMediaQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.hasPrivilegeEdit && !storageSpaceMedia.sharedMediaToken)?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
          _vm.openBottomSheet('StorageSpaceMediaUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.hasPrivilegeFinaleDelete && !storageSpaceMedia.sharedMediaToken)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceMediaDelete",modifiers:{"StorageSpaceMediaDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setStorageSpaceMediaData(storageSpaceMedia)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(
        _vm.hasPrivilegeChangeActivationType &&
        !storageSpaceMedia.sharedMediaToken
      )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceMediaChangeActivationType",modifiers:{"StorageSpaceMediaChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setStorageSpaceMediaData(storageSpaceMedia)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }